import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiTakeMyMoney } from "react-icons/gi";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import Swal from 'sweetalert2';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaEye } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";
import approveicon from "../../../src/assets/img/approveicon.png"
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
export default function Customerinvestmentsapproved() {

  const [data, setData] = useState([]);
  const [baseurl, setbaseurl] = useState();

  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search term
  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const customer_id = localStorage.getItem("customer_id");
  const [Allcompany, setCompany] = useState();
  const [Active, setActive] = useState();
  const [pending, setPending] = useState();
  const [Expired, setExpired] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [investId, setInvestId] = useState(null);
  const [custId, setCustId] = useState(null);
  const [investPlainId, setInvestPlainId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [show, setShow] = useState(false);

  const getCurrentDateFormatted = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const year = today.getFullYear();
    return `${month}-${day}-${year}`;
  };
  const [selectedDate, setSelectedDate] = useState(getCurrentDateFormatted());
  const [errorMessage, setErrorMessage] = useState("");
  const [JoiningDates, setJoiningDates] = useState("");
  const [loading, setLoading] = useState(false);
  const handleJoiningDateChange = (date) => {
    if (date) {
      setSelectedDate(
        date
      );
    } else {
      setSelectedDate(

        null
      );
    }
  };
  const currentDate = new Date();  // Get the current date
  currentDate.setHours(0, 0, 0, 0);
  const AprovedHandlechange = (invest_id, cust_id, invest_plain_id, company_id) => {
    // handleApproved(invest_id,cust_id, invest_plain_id, company_id);
    // console.warn(cust_id, invest_plain_id, company_id)
    setInvestId(invest_id);
    setCustId(cust_id);
    setInvestPlainId(invest_plain_id);
    setCompanyId(company_id);
    handleShow();
  };

  const handleClose = () => {
    setShow(false);
    setSelectedDate(null);
  };
  const handleShow = () => {
    setShow(true)
    setSelectedDate(getCurrentDateFormatted());
  };

  // const handleShow = () => setShow(true);


  const handleApproved = async () => {
    console.warn("selectedDate", selectedDate)
    if (!selectedDate) {
      setErrorMessage("Date is required. Please select a valid date.");
      return;
    }
    let dateObj = new Date(selectedDate);

    // Check if it's an invalid date
    if (isNaN(dateObj.getTime())) {
      setErrorMessage("Please select a valid date.");
      return;
    }

    if (dateObj < JoiningDates) {
      setErrorMessage("Selected date cannot be earlier than the joining date.");
      console.warn("selectedDate", selectedDate, "JoiningDates", JoiningDates);
      return;
    }

    const now = new Date();

    // Combine selectedDate and current time
    const combinedDateTime = new Date(
      dateObj.getFullYear(),
      dateObj.getMonth(),
      dateObj.getDate(),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );
    const startDateTime = `${combinedDateTime.getFullYear()}-${(combinedDateTime.getMonth() + 1).toString().padStart(2, '0')}-${combinedDateTime.getDate().toString().padStart(2, '0')} ${combinedDateTime.getHours().toString().padStart(2, '0')}:${combinedDateTime.getMinutes().toString().padStart(2, '0')}:${combinedDateTime.getSeconds().toString().padStart(2, '0')}`;

    console.warn("startDateTime:", startDateTime);
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");


      const formData = new FormData();
      formData.append("cust_id", custId);
      formData.append("invest_plain_id", investPlainId);
      formData.append("invest_id", investId);
      formData.append("company_id", companyId);
      formData.append("start_date_time", startDateTime); // Use the selected date here

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}approve-swan-selfcompany-customers-plans`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data;

      if (data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: "Plan Activated Successfully!",
          color: "white",
          background: 'transparent',
        });
        handleClose();
        // fetchDataSingleInverstPlan();
        fetchData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: "Unexpected response from the server.",
        });
        // handleClose();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: "An error occurred while processing your request.",
      });
      // handleClose();
    }

  };
  const fetchData = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}customer-swan-selfcompany-investments-pending-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const data1 = response.data.image_url;
      setbaseurl(data1);
      setData(data);
      setCompany(response.data.totaluserinvestments);
      setActive(response.data.totalActiveuserinvestments);
      setPending(response.data.totaluserinvestmentspendings);
      setExpired(response.data.totalexpiredinvestmentplans);
      const formattedJoiningDate = response.data.customerJoiningDates.split("-"); // Assume format is YYYY-MM-DD

      const joiningDateInMMDDYYYY = `${formattedJoiningDate[1]}-${formattedJoiningDate[0]}-${formattedJoiningDate[2]}`;

      setJoiningDates(joiningDateInMMDDYYYY);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const fetchDataSingleInverstPlan = async () => {
    try {
      setLoading(true);

      // Fetch data from API
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", customer_id);
      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}narmal-sinlge-cust-invested-plans-counts`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);

      // setCompany(response.data.totalNArmaluserinvestments);
      // setActive(response.data.totalActiveNarmaluserinvestments);
      // setPending(response.data.totalNarmaluserinvestmentspendings);
      // setExpired(response.data.totalexpiredNarmalUserinvestmentplans);

      setLoading(false);

    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // fetchDataSingleInverstPlan();
  }, []);

  const filteredData = data.filter((item) => item.cust_id == customer_id && item.customer_name.toLowerCase().includes(searchTerm.toLowerCase()));

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };



  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Customer Investments Plan Analytics</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to='/Custumerdetails' className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
        <div className="col-md-3">
          <Link to='/Single-Customer-Plan' className="linkWithoutUnderline">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">Total</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-primary" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                      {Allcompany}
                    </h5>
                  </div>
                  <div className="col-auto ps-0">
                    <div className="echart-bar-weekly-sales h-100" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3">
          <Link to='/Single-Customer-Plan-Active' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Active</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-success" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                      {Active}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="col-md-3">
          <Link to='/Single-Customer-Plan-Pending' className="linkWithoutUnderline active">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Pending</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-warning" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-warning">
                      {pending}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
        {/* <div className="col-md-3">
          <Link to='/Customer-investments-Inactive' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Inactive</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-secondary" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-secondary">
                      {Active}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div> */}


        <div className="col-md-3">
          <Link to='/Single-Customer-Plan-Expired' className="linkWithoutUnderline">
            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2">Expired</h5>
              </div>
              <div className="card-body d-flex flex-column justify-content-end">
                <div className="row justify-content-between">
                  <div className="col pe-0">
                    <GiTakeMyMoney className="fs-4 text-danger" />
                  </div>
                  <div className="col ps-0">
                    <h5 className="font-sans-serif lh-1 mb-1 fs-5 text-danger">
                      {Expired}
                    </h5>
                  </div>
                  <div className="col-auto ps-0 mt-n4">
                    <div
                      className="echart-default-total-order"
                      data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","Week 6","Week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}'
                      data-echart-responsive="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div >

      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />

        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h5 className="mb-0"> Customer Investment Plan List</h5>
            </div>
          </div>
        </div>

      </div>
      <div className="card mb-3">

        <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table table-sm mb-0 table-dashboard fs--1">
              <thead className="bg-200 text-900">
                <tr>
                  <th className="sort">Sr. No.</th>
                  <th className="sort">Name</th>
                  {/* <th className="sort"> Email</th>  */}
                  <th className="sort">Plan Name</th>
                  <th className="sort">Amount</th>
                  <th className="sort"> Duration</th>
                  <th className="sort"> Interest Rate</th>
                  {/* <th className="sort"> Duration Type</th> */}

                  <th className="sort">Status</th>
                  <th className="sort">Action</th>
                </tr>
              </thead>
              <tbody className="list  small-font" id="table-ticket-body">
                {paginatedData && paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    item.cust_id == customer_id ? (
                      <tr key={index}>
                        <td>{startIndex + index + 1}</td>
                        <td className="capitalize">{item.customer_name}</td>

                        {/* <td><a href={`mailto:${item.email}`}>{item.email}</a></td> */}
                        <td className="capitalize">{item.plan_name}</td>
                        <td className="capitalize">{item.invested_amount}</td>
                        <td className="capitalize">{item.plan_duration} {item.plan_duration_type}</td>
                        <td>{item.interest_rate} %</td>
                        <td>
                          <span className={`badge ${item.plan_status === 'approve' && item.is_expired === 'expired' ? 'bg-danger' :
                            item.plan_status === 'pending' && item.is_expired === '' ? 'bg-warning' :
                              item.plan_status === 'approve' && item.is_expired === 'active' ? 'bg-success' :
                                'bg-danger'}`}>
                            {item.plan_status === 'approve' && item.is_expired === 'expired' ? 'Expired' :
                              item.plan_status === 'pending' && item.is_expired === '' ? 'Pending' :
                                item.plan_status === 'approve' && item.is_expired === 'active' ? 'Approved' :
                                  'Expired'}
                          </span>
                        </td>
                        <td>
                          {item.plan_status !== 'approve' && item.is_expired !== 'expired' ? (
                            <>
                              <OverlayTrigger
                                key="Activated"
                                placement="top"

                                overlay={<Tooltip id={`Activated`}>Activated Now</Tooltip>}
                              >
                                <button
                                  onClick={() => AprovedHandlechange(item.invest_id, item.cust_id, item.invest_plain_id, item.company_id)}
                                  style={{ "--i": "#27bcfd" }}
                                  className="btn bg-primary  action_button"
                                >
                                  <FaCheckCircle className="color_link text-light" />
                                </button>
                              </OverlayTrigger>

                            </>
                          ) : item.plan_status === 'approve' && item.is_expired === 'active' ? (
                            <>
                              <OverlayTrigger
                                key="View"
                                placement="top"

                                overlay={<Tooltip id={`View`}>View</Tooltip>}
                              >
                                <button

                                  style={{ "--i": "#27bcfd" }}
                                  className="btn bg-success  action_button"
                                >
                                  <FaEye className="color_link text-light" />
                                </button>
                              </OverlayTrigger>

                            </>
                          ) : null}
                        </td>
                      </tr>
                    ) : null
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}

                {/* {paginatedData && paginatedData.every(item => item.cust_id != customer_id) && (
    <tr>
      <td colSpan="9" className="text-center py-4">
        No data found.
      </td>
    </tr>
  )} */}
              </tbody>
            </table>
          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>

        <Modal show={show} centered>
          <Modal.Body>
            <div className="d-flex justify-content-center mt-4 mb-4 approvecss">
              <img src={approveicon} alt="Approve Icon" />
            </div>
            <h2 style={{ color: "white", fontSize: "18px", textAlign: "center" }}>
              Do you really want to approve this plan? Please select an approval date.
            </h2>
            <Form>
              <div className="d-flex justify-content-center mt-2">
                <Form.Group controlId="approvalDate">
                  <div className="col-md-12 form_details">
                    <div className="form-group local-forms">
                      <DatePicker
                        className={`form-control `}
                        selected={selectedDate || null}
                        onChange={handleJoiningDateChange}

                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD-MM-YYYY"
                        maxDate={currentDate}
                        minDate={JoiningDates}
                        customInput={
                          <InputMask
                            mask="99/99/9999"
                            maskChar={null}
                            value={
                              selectedDate
                                ? selectedDate
                                : ""
                            }
                            onChange={(e) => handleJoiningDateChange(e.target.value)}
                          />
                        }
                      />
                      {/* <DatePicker
              className="form-control"
              selected={selectedDate ? new Date(selectedDate) : null} // Convert to Date object if formData.joining_date exists
              onChange={handleJoiningDateChange} 
              dateFormat="dd/MM/yyyy"
              placeholderText="DD-MM-YYYY"
              maxDate={currentDate}
              customInput={
                <InputMask
                  mask="99/99/9999"
                  maskChar={null}
                  value={selectedDate}
                  onChange={handleJoiningDateChange} 
                />
              }
            /> */}
                    </div>
                  </div>
                  {errorMessage && (
                    <div style={{ color: "red", marginTop: "5px" }}>{errorMessage}
                    </div>
                  )}
                </Form.Group>
              </div>
            </Form>
            <div className="d-flex justify-content-center mt-3">
              <Button style={{ background: "#3085d6" }} onClick={handleApproved}>
                Yes, approve it!
              </Button>
              <Button style={{ background: "#d33" }} className="mx-1" onClick={handleClose}>
                Cancel!
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
}
