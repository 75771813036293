import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";
import InputMask from 'react-input-mask';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
const d = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 2, 3, 4, 0, 6, 7, 8, 9, 5],
  [2, 3, 4, 0, 1, 7, 8, 9, 5, 6],
  [3, 4, 0, 1, 2, 8, 9, 5, 6, 7],
  [4, 0, 1, 2, 3, 9, 5, 6, 7, 8],
  [5, 9, 8, 7, 6, 0, 4, 3, 2, 1],
  [6, 5, 9, 8, 7, 1, 0, 4, 3, 2],
  [7, 6, 5, 9, 8, 2, 1, 0, 4, 3],
  [8, 7, 6, 5, 9, 3, 2, 1, 0, 4],
  [9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
];

const p = [
  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  [1, 5, 7, 6, 2, 8, 3, 0, 9, 4],
  [5, 8, 0, 3, 7, 9, 6, 1, 4, 2],
  [8, 9, 1, 6, 0, 4, 3, 5, 2, 7],
  [9, 4, 5, 3, 1, 2, 6, 8, 7, 0],
  [4, 2, 8, 6, 5, 7, 3, 9, 0, 1],
  [2, 7, 9, 3, 8, 0, 6, 4, 1, 5],
  [7, 0, 4, 6, 9, 1, 3, 2, 5, 8],
];

const inv = [0, 4, 3, 2, 1, 5, 6, 7, 8, 9];

// Function to validate Aadhaar number using Verhoeff algorithm
const validateVerhoeff = (num) => {
  let c = 0;
  const myArray = stringToReversedIntArray(num);

  myArray.forEach((digit, i) => {
    c = d[c][p[i % 8][digit]];
  });

  return c === 0;
};

// Helper function to convert a string into a reversed array of integers
const stringToReversedIntArray = (num) => {
  const myArray = Array.from(num).map((char) => parseInt(char, 10));
  return myArray.reverse();
};
export default function Addsmalladmins_Partner() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [fileimage, setFileimage] = useState(null);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [dataplan, setDataplan] = useState([]);
  const [baseurl, setBaseUrl] = useState("");
  const [newImageadhar, setNewImageadar] = useState(null);
  const [adharimg, setAdharImg] = useState("");
  const [totalPercentage, settotalPercentage] = useState(0);
  const [PartnerPercentage, setPartnerShip] = useState(0);
  const [formData1, setFormData1] = useState("");
  const [newImageadharback, setNewImageadarBack] = useState(null);
  const [adharbackimg, setAdharBackImg] = useState("");

  const [newImagepan, setNewImagepan] = useState(null);
  const [panimg, setPanImg] = useState("");
  const [formData, setFormData] = useState({
    first_name: "",
    aadharnumber: "",
    partnership: "",
    pannumber: "",
    invested_amount: "",
    email: "",
    address: "",
    cust_ac_no: "",
    acc_holder_name: "",
    bank_branch: "",
    bank_address: "",
    phone: "",
    altername_phone: "",
    company_id: "",
    plan_id: "",
    marital_status: "",
    ifsc_code: "",
    password: "",
    gender: "",
    profile_image: null,
    pan_image: null,
    uan_image: null,
    back_uan_image: null,
    joining_date: null,
  });
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage("");
    setNewImage(null);
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageProfile();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setFileimage(URL.createObjectURL(file));
        setNewImage(file);
      }
    }
  };
  const handleClearImageProfile = () => {
    setFileimage("");
    setNewImage(null);
    // Reset the file input field
    const fileInput = document.getElementById("profile_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handleAdharImageChange = (e) => {
    const file = e.target.files[0];
    setNewImageadar(null);
    setAdharImg("");
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImage();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setAdharImg(URL.createObjectURL(file));
        setNewImageadar(file);
      }
    }
  };
  const handleClearImage = () => {
    setNewImageadar(null);
    setAdharImg("");
    const fileInput = document.getElementById("uan_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handleAdharBackImageChange = (e) => {
    const file = e.target.files[0];
    setNewImageadarBack(null);
    setAdharBackImg("");
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageback();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setAdharBackImg(URL.createObjectURL(file));
        setNewImageadarBack(file);
      }
    }
  };
  const handleClearImageback = () => {
    setNewImageadarBack(null);
    setAdharBackImg("");

    const fileInput = document.getElementById("back_uan_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handlePanImageChange = (e) => {
    const file = e.target.files[0];
    setNewImagepan(null);
    setPanImg("");
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImagePan();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setPanImg(URL.createObjectURL(file));
        setNewImagepan(file);
      }
    }
  };
  const handleClearImagePan = () => {
    setNewImagepan(null);
    setPanImg("");

    const fileInput = document.getElementById("pan_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "first_name") {
      if (value.length > 30 || !/^[a-zA-Z\s]*$/.test(value)) {
        return
      }
    }
    if (name === "phone" && (value.length > 10 || isNaN(value))) {
      return;
    }
    if (name === "altername_phone" && (value.length > 10 || isNaN(value))) {
      return;
    }
    // Validation for customer account number
    if (name === "cust_ac_no" && (value.length > 17 || isNaN(value))) {
      return; // Reject invalid input
    }
    let formattedValue = value;

    if (name === "aadharnumber") {
      formattedValue = value.replace(/\D/g, "").slice(0, 12);
    }
    if (name === "ifsc_code") {
      formattedValue = value.replace(/[^A-Za-z0-9]/g, "").toUpperCase().slice(0, 11);
    }
    else if (name === "pannumber") {
      formattedValue = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .slice(0, 10);
    }
    if (name === "partnership") {
      formattedValue = value.replace(/\D/g, "").slice(0, 12);
    }
    setFormData({ ...formData, [name]: formattedValue });

    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };
  useEffect(() => {
    fetchData1();
  }, []);

  const handleJoiningDateChange = (date) => {
    if (date) {
      setFormData1({
        ...formData1,
        joining_date: date, // Store the raw Date object
      });
    } else {
      setFormData1({
        ...formData1,
        joining_date: null,
      });
    }
  };
  // const currentDate = new Date();
  // currentDate.setHours(0, 0, 0, 0);

  const fetchData123 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("broker_id");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-partner-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      console.log("dada", data)


      const totalEmp = response.data.totalAddedPercentage;


      settotalPercentage(totalEmp)

    } catch (error) {
      console.error("Error fetching data:", error);

    }
  };

  useEffect(() => {
    fetchData123();
  }, []);
  const fetchData1 = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const Partner_id = localStorage.getItem("Partner_id");
      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("cust_id", Partner_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-swan-selfcompany-partner`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      const data = response.data.data;
      console.warn("data", response)
      setFormData(data[0]);
      setPartnerShip(data[0].partnership)
      const dataurl = response.data.url;
      setBaseUrl(dataurl)
      setFormData(data[0]);
      if (data && data[0]?.joining_date) {
        // Format the date
        const formattedDate = new Date(data[0].joining_date);
        const joiningDateString = data[0].joining_date;
        console.warn("Formatted Date:", formattedDate);

        const [day, month, year] = joiningDateString.split("-");
        const joiningDate = new Date(`${year}-${month}-${day}`);
        setFormData1((prev) => ({
          ...prev,
          joining_date: joiningDate,
        }));
      }
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-company-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataine_plan = async () => {
    try {
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("status", "1");

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-investment-active-plans-lists`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      setDataplan(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataine_plan();
  }, []);
  const validateForm = () => {
    const {
      first_name,
      last_name,
      email,
      phone,
      aadharnumber,
      parntership,
      pannumber,

    } = formData;

    // Helper function to check if a value is empty
    const isEmpty = (value) =>
      !value || (typeof value === "string" && !value.trim());

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };


    const isValidPhoneNumber = (phone) => {

      const phoneRegex = /^\d{10}$/;
      return phoneRegex.test(phone);
    };

    if (isEmpty(first_name)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Full Name is required",
        // timer: 2000,
      });
      return false;
    }


    if (isEmpty(email) || !isValidEmail(email)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Valid Email is required",
        // timer: 2000,
      });
      return false;
    }

    if (isEmpty(phone) || !isValidPhoneNumber(phone)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Valid Phone Number is required",
        // timer: 2000,
      });
      return false;
    }

    // if (!parntership) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Error",
    //     text: "parntership % is required",
    //     // timer: 2000,
    //   });
    //   return false;
    // }

    if (isEmpty(pannumber)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "PAN Number is required",
        // timer: 2000,
      });
      return false;
    }


    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const partnershipValue = Number(formData.partnership);
    const Remainings = 100 - totalPercentage;
    const PartnerPercentageNew = (formData.partnership) - PartnerPercentage;
    console.warn("PartnerPercentage", PartnerPercentage, Remainings)
    // Check if partnership exceeds 100
    if (partnershipValue > 100) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "Partnership cannot exceed 100%",
      });
      setLoading(false);
      return;
    }
    if (PartnerPercentageNew > Remainings) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Partnership cannot exceed 100% (Remaining: ${100 - totalPercentage}%)`,
      });
      setLoading(false);
      return;
    }
    // alert((100-totalPercentage) - partnershipValue)

    if (Remainings < 0) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: `Partnership percentage is insufficient (Required: ${100 - totalPercentage}%)`,
      });
      setLoading(false);
      return;
    }


    const requiredFields = [
    ];

    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;

    if (!panRegex.test(formData.pannumber)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid PAN Number",
        html: `
  <div class="swal-custom-text text-start">
    PAN numbers must be:
    <ul style="list-style:auto">
      <li>Contain the first 5 characters as uppercase alphabets.</li>
      <li>Contain the next 4 characters as digits.</li>
      <li>End with 1 uppercase alphabet.</li>
    </ul>
    Please enter a valid PAN number.
  </div>
`, // Use `html` for custom content
        confirmButtonText: "OK", // Button text for confirmation
        customClass: {
          htmlContainer: "swal-custom-html", // Apply class to the text container
        }, // Adds an OK button
      });
      setLoading(false);
      return;
    }

    // Aadhaar validation
    if (!validateVerhoeff(formData.aadharnumber)) {
      Swal.fire({
        icon: "warning",
        title: "Invalid Aadhaar Number",
        text: "The Aadhaar number entered is invalid. Please check and try again.",
        confirmButtonText: "OK",
      });
      setLoading(false);
      return;
    }
    if (formData.cust_ac_no.length < 8 || formData.cust_ac_no.length > 17) {
      Swal.fire({
        icon: 'warning',
        title: 'Account Number Issue',
        text: `Account numbers must be between 8 and 17 characters in length. Please choose correct account number length`,
        confirmButtonText: 'OK', // Adds an OK button
      });
      setLoading(false);
      return;
    }
    // Show confirmation dialog
    const confirmResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to update this partner's information? You won't be able to undo this action.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update the partner!',
      cancelButtonText: 'Cancel',
    });

    if (!confirmResult.isConfirmed) {
      setLoading(false);
      Swal.fire({
        icon: 'info',
        title: 'Cancelled',
        text: 'The partner update was cancelled.',
      });
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const Partner_id = localStorage.getItem("Partner_id");

      const updatedFormData = new FormData();
      updatedFormData.append("user_id", broker_id);
      updatedFormData.append("cust_id", Partner_id);
      updatedFormData.append("status", "1");

      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== "" && value !== "null") {
          if (key !== "company_name") { // Keep this condition as needed
            // Check if the value is an instance of File (binary)
            if (value instanceof File) {
              updatedFormData.append(key, value); // Append only binary files
            } else {
              // Append only if it's not a binary file
              if (key !== "pan_image" && key !== "uan_image" && key !== "profile_image" && key !== "back_uan_image") {
                updatedFormData.append(key, value);
              }
            }
          }
        }
      });
      // Object.entries(formData).forEach(([key, value]) => {
      //   if (value !== null && value !== "" && value !== "null") {
      //     if (key !== "company_name" && key !== "profile_image") {
      //       updatedFormData.append(key, value);
      //     }
      //   }
      // });
      if (newImage) {
        updatedFormData.append("profile_image", newImage);
      }
      if (newImagepan) {
        updatedFormData.append("pan_image", newImagepan);
      }

      if (newImageadhar) {
        updatedFormData.append("aadhar_front_image", newImageadhar);
      }
      if (newImageadharback) {
        updatedFormData.append("aadhar_back_image", newImageadharback);
      }


      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}update-swan-selfcompany-partner`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: updatedFormData,
      };

      const response = await axios(config);

      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          // timer: 1500
        });

        setTimeout(() => {
          navigate("/PartnerTransaction");
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          // timer: 2000
        });

      }
    } catch (error) {
      console.warn("Error add-swan-selfcompany-Partner :", error);
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response.data.error.email[0],
          // timer: 2000
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Failed to add Partner ",
          // timer: 2000
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Partner </h3>
              <h4>Partnership Remaining: {100 - totalPercentage}%</h4>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/PartnerTransaction" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="card mb-4">
        <form className="card-body" onSubmit={handleSubmit}>
          <h5>Partner  Details</h5>
          <div className="row g-3 mt-2">
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="first_name">
                  Full Name <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="first_name"
                  name="first_name"
                  type="text"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="phone">
                  Mobile Number <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="phone"
                  name="phone"
                  type="text"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="email">
                  Email <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control`}
                  id="email"
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="altername_phone">
                  Alternative Mobile Number
                </label>
                <input
                  className={`form-control`}
                  id="altername_phone"
                  type="text"
                  name="altername_phone"
                  value={formData.altername_phone != "null" ? formData.altername_phone : ''}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="pannumber">
                  Pan Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.pannumber ? "input-error" : ""
                    }`}
                  id="pannumber"
                  type="text"
                  placeholder="Enter your PAN number (e.g., ABCDE1234F)"
                  name="pannumber"
                  value={formData.pannumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="pan_image">
                  PAN Card Image <span className="login-danger"></span>
                </label>
                <input
                  // className="form-control"
                  className={`form-control ${errors.pan_image ? "input-error" : ""
                    }`}
                  id="pan_image"
                  // name="pan_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  // value={formData.pan_image}
                  onChange={handlePanImageChange}
                />
                {panimg ? (
                  <div className="mt-3 image_box">
                    <img
                      src={panimg}
                      alt="Selected profile"
                      style={{ height: "100px" }}
                    />
                    <ImCross onClick={handleClearImagePan} />
                  </div>
                ) : formData.pan_image !== null ? (
                  <div className="mt-3 image_box">
                    <img src={`${baseurl}/${formData.pan_image}`} height={100} width={100} alt="Profile" className="img-fluid" />
                  </div>

                ) : null}

              </div>

            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="aadharnumber">
                  Aadhaar Number <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.aadharnumber ? "input-error" : ""
                    }`}
                  id="aadharnumber"
                  type="text"
                  name="aadharnumber"
                  value={formData.aadharnumber}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="uan_image">
                  Aadhaar Front Image <span className="login-danger"></span>
                </label>
                <input
                  // className="form-control"
                  className={`form-control ${errors.uan_image ? "input-error" : ""
                    }`}
                  id="uan_image"
                  // name="uan_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  // value={formData.uan_image}
                  onChange={handleAdharImageChange}
                />

                {adharimg ? (
                  <div className="mt-3 image_box">
                    <img
                      src={adharimg}
                      alt="Selected Aadhaar"
                      style={{ height: "100px" }}
                    />
                    <ImCross onClick={handleClearImage} />
                  </div>

                ) : formData.uan_image !== null ? (
                  <div className="mt-3 image_box">
                    <img src={`${baseurl}/${formData.uan_image}`} height={100} width={100} alt="Profile" className="img-fluid" />
                  </div>

                ) : null}
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">


              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="back_uan_image">
                  Aadhaar Back Image <span className="login-danger"></span>
                </label>
                <input
                  // className="form-control"
                  className={`form-control ${errors.back_uan_image ? "input-error" : ""
                    }`}
                  id="back_uan_image"
                  // name="back_uan_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"
                  // value={formData.back_uan_image}
                  onChange={handleAdharBackImageChange}
                />

                {adharbackimg ? (
                  <div className="mt-3 image_box">
                    <img
                      src={adharbackimg}
                      alt="Selected Aadhaar"
                      style={{ height: "100px" }}
                    />
                    <ImCross onClick={handleClearImageback} />
                  </div>
                ) : formData.back_uan_image !== null ? (
                  <div className="mt-3 image_box">

                    <img src={`${baseurl}/${formData.back_uan_image}`} height={100} width={100} alt="Profile" className="img-fluid" />
                  </div>
                ) : null}

              </div>
            </div>

            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="gender">
                  Gender <span className="login-danger"></span>
                </label>
                <select
                  className="form-select"
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="marital_status">
                  Marital Status <span className="login-danger"></span>
                </label>
                <select
                  className="form-select"
                  id="marital_status"
                  name="marital_status"
                  value={formData.marital_status}
                  onChange={handleInputChange}
                >
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="address">
                  Address
                </label>
                <textarea
                  className={`form-control `}
                  id="address"
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="aadharnumber">
                  Joining Date <span className="login-danger">*</span>
                </label>
                {/* <input
                  className={`form-control ${
                    errors.joining_date ? "input-error" : ""
                  }`}
                  id="joining_date"
                  type="text"
                  name="joining_date"
                  value={formData.joining_date}
                  onChange={handleInputChange}
                /> */}
                <DatePicker
                  className={`form-control ${errors.joining_date ? "input-error" : ""}`}
                  selected={formData1.joining_date || null}
                  onChange={handleJoiningDateChange}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD-MM-YYYY"
                  customInput={
                    <InputMask
                      mask="99/99/9999"
                      maskChar={null}
                      value={
                        formData1.joining_date
                          ? formData1.joining_date.toLocaleDateString("en-GB")
                          : ""
                      }
                      onChange={(e) => handleJoiningDateChange(e.target.value)}
                    />
                  }
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="pan_image">
                  Profile Image <span className="login-danger"></span>
                </label>
                <input
                  className="form-control"
                  id="profile_image"
                  accept=".jpeg,.jpg,.png"
                  type="file"

                  name="profile_image"
                  onChange={handleImageChange}
                />
                {fileimage ? (
                  <div className="mt-3 image_box">
                    <img
                      src={fileimage}
                      alt="Profile Preview"
                      style={{
                        width: "100px",
                        height: "100px",
                      }}
                    />
                    <ImCross onClick={handleClearImageProfile} />
                  </div>
                ) : formData.profile_image !== null ? (
                  <div className="mt-3 image_box">
                    <img src={`${baseurl}/${formData.profile_image}`} height={100} width={100} alt="Profile" className="img-fluid" />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="partnership">
                  Parntership <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.partnership ? "input-error" : ""
                    }`}
                  id="partnership"
                  type="text"
                  name="partnership"
                  value={formData.partnership}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <h5>Bank Details</h5>
          <div className="row g-3 mt-2">
            <div className="col-md-6 form_details mt-3">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="cust_ac_no">
                  Partner  Account Number <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control ${errors.cust_ac_no ? "input-error" : ""
                    }`}
                  id="cust_ac_no"
                  name="cust_ac_no"
                  type="text"
                  value={formData.cust_ac_no}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="acc_holder_name">
                  Account Holder Name <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control ${errors.acc_holder_name ? "input-error" : ""
                    }`}
                  id="acc_holder_name"
                  name="acc_holder_name"
                  type="text"
                  value={formData.acc_holder_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="ifsc_code">
                  IFSC CODE <span className="login-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.ifsc_code ? "input-error" : ""
                    }`}
                  id="ifsc_code"
                  name="ifsc_code"
                  placeholder="e.g., SBIN0001234"
                  type="text"
                  value={formData.ifsc_code}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="bank_branch">
                  Branch Name <span className="login-danger"></span>
                </label>
                <input
                  className={`form-control ${errors.bank_branch ? "input-error" : ""
                    }`}
                  id="bank_branch"
                  type="text"
                  name="bank_branch"
                  value={formData.bank_branch}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-6 form_details">
              <div className="form-group local-forms">
                <label className="form-label" htmlFor="bank_address">
                  Bank Adress{" "}
                  <span className="login-danger"></span>
                </label>
                <textarea
                  className={`form-control ${errors.bank_address ? "input-error" : ""
                    }`}
                  id="bank_address"
                  type="text"
                  name="bank_address"
                  value={formData.bank_address}
                  onChange={handleInputChange}
                />

              </div>
            </div>





          </div>


          <div className="pt-4 w-100 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary me-sm-3 me-1 waves-effect waves-light"
              disabled={loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>

    </div>
  );
}
