import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";
import { Link } from 'react-router-dom';
export default function Investmentedit() {
  const broker_id = localStorage.getItem("broker_id");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const investmentedit_id = localStorage.getItem("investment_id");
  const [newImage, setNewImage] = useState();
  const [fileimage, setFileimage] = useState();
  const [baseurl, setBaseurl] = useState('');
  const [data, setData1] = useState([]);
  const [datatype, setDatatype] = useState([]);
  const [errors, setErrors] = useState({});
  const [datacom, setDatacompny] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    minimum_investment: "",
    plan_duration: "",
    plan_duration_type: "",
    interest_rate: "",
    invested_amount: "",
    is_compound: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFileimage("");
    setNewImage(null);
    if (file) {
      console.log("File type:", file.type); // Debugging step to log file type

      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        handleClearImageProfile();

        Swal.fire({
          title: "Invalid File Type",
          text: "Only JPEG, JPG, and PNG files are allowed!",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        setFileimage(URL.createObjectURL(file));
        setNewImage(file);
      }
    }
  };
  const handleClearImageProfile = () => {
    setFileimage("");
    setNewImage(null);
    // Reset the file input field
    const fileInput = document.getElementById("plan_image");
    if (fileInput) {
      fileInput.value = "";
    } else {
      console.warn("File input element not found");
    }
  };
  const handleClearImageProfile1 = () => {
    setBaseurl("");


  };
  useEffect(() => {
    fetchDataa();
    fetchData1();

  }, []);
  const fetchDataa = async () => {
    const token = localStorage.getItem("bear_token");
    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-paln-duration`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;
      const transformedData = rawData.map(item => ({
        id: item,
        name: `${item}`
      }));
      setData1(transformedData);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchData1 = async () => {
    const token = localStorage.getItem("bear_token");

    try {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}swan-selfcompany-plan-duration-type`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
      };

      const response = await axios(config);
      const rawData = response.data.data;
      const transformedData = rawData.map(item => ({
        id: item,
        name: `${item}`
      }));

      console.warn(transformedData);

      // Set transformed data
      setDatatype(transformedData);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const formData = new FormData();
      formData.append("user_id", broker_id);
      formData.append("id", investmentedit_id);

      formData.append("status", '1');

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}view-swan-selfcompany-investment-plans`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: formData
      };

      const response = await axios(config);
      const data = response.data.data;
      const dataurl = response.data.url;
      setBaseurl(dataurl)
      console.warn(data);
      setFormData(data[0]);
    } catch (error) {
      console.error("Error fetching user broker:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    switch (name) {
      case "minimum_investment":
        // Validate: numeric only, max 10 digits, no leading 0
        if (/^[0-9]*$/.test(value)) {
          if (value.length > 10) return; // Limit to 10 digits
          if (value.length === 1 && value === "0") return; // Prevent leading 0
          formattedValue = value; // Valid value
        } else {
          return; // Exit for invalid input
        }
        break;

      case "interest_rate":



        if (name === "interest_rate") {
          if (isNaN(value) || value < 1 || value > 50 || value.length > 8) {
            // Clear the input box if invalid
            setFormData({ ...formData, [name]: "" });
            return;
          }
        }
        else {
          return; // Exit for invalid input
        }
        break;

      case "name":
        // Validate: alphabetic only, max 40 characters
        formattedValue = value.replace(/[^A-Za-z]/g, "").slice(0, 40);
        break;

      default:
        // Handle other fields if necessary
        break;
    }

    // Update form data and clear any errors for this field
    setFormData({ ...formData, [name]: formattedValue });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
  };

  const validateForm = () => {
    const { name, minimum_investment, plan_duration, plan_duration_type, interest_rate } = formData;
    const isEmpty = (value) => !value || (typeof value === 'string' && !value.trim());

    if (isEmpty(name)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Plan Name is required',
        timer: 2000
      });
      return false;
    }
    if (isEmpty(minimum_investment)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Minimum Investment is required',
        timer: 2000
      });
      return false;
    }
    if (isEmpty(plan_duration)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Plan Duration is required',
        timer: 2000
      });
      return false;
    }
    if (isEmpty(plan_duration_type)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Plan Duration Type is required',
        timer: 2000
      });
      return false;
    }
    if (isEmpty(interest_rate)) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Interest Rate is required',
        timer: 2000
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!validateForm()) {
      setLoading(false);
      return;
    }
    const requiredFields = [
      // { field: 'name', name: 'Name' },
      // { field: 'description', name: 'Description' },
      // { field: 'minimum_investment', name: 'Minimum Investment' },
      // { field: 'plan_duration', name: 'Plan duration' },
      // { field: 'plan_duration_type', name: 'Plan duration type' },
      // { field: 'interest_rate', name: 'Interest Rate' },
      // { field: 'status', name: 'Status' },
    ];

    // let hasError = false;
    // requiredFields.forEach(({ field, name }) => {
    //     if (!formData[field]) {
    //         toast.error(`Please fill in the ${name}.`);
    //         hasError = true;
    //     }
    // });

    // if (hasError) {
    //     setLoading(false);
    //     return;
    // }
    let validationErrors = {};
    let hasError = false;

    for (const { field, name } of requiredFields) {
      if (!formData[field]) {
        validationErrors[field] = `Please fill in the ${name}.`;
        hasError = true;
      }
    }

    setErrors(validationErrors);

    if (hasError) {
      setLoading(false);
      return;
    }
    const confirmUpdate = await Swal.fire({
      title: 'Update Investment Plan',
      text: "Are you sure you want to proceed with updating this investment plan?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#28a745', // Green for confirmation
      cancelButtonColor: '#d33', // Red for cancel
      confirmButtonText: 'Update Plan',
      cancelButtonText: 'Cancel',
    });

    if (!confirmUpdate.isConfirmed) {
      setLoading(false);
      Swal.fire({
        icon: 'info',
        title: 'Update Cancelled',
        text: 'No changes were made to the investment plan.',
      });
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const updatedFormData = new FormData();
      updatedFormData.append("user_id", broker_id);
      Object.keys(formData).forEach(key => {
        if (formData[key] !== null && formData[key] !== undefined && formData[key] !== "null") {
          updatedFormData.append(key, formData[key]);
        }
      });
      if (newImage) {
        updatedFormData.append("plan_image", newImage);
      }

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}update-swan-selfcompany-investment-plans`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
        data: updatedFormData
      };

      const response = await axios(config);
      const data = response.data.status;

      if (data === 1) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.data.message,
          timer: 1500
        });

        setTimeout(() => {
          navigate('/Investment-management')
        }, 2000);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data.message,
          timer: 2000
        });
      }
    } catch (error) {
      console.error("Error updating user broker:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card mb-3">
        <div className="bg-holder d-none d-lg-block bg-card" style={{ backgroundImage: "url(../../assets/img/icons/spot-illustrations/corner-4.png)" }} />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Update Investment Plan</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Investment-management" className="">
                <button className="btn btn-success">Back</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form>
        <div className="card">
          <div className="card-body">
            <Container fluid>
              <Row>
                <div className="col-md-6 form_details">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="name">
                      Plan Name <span className="login-danger"></span>
                    </label>
                    <input
                      className={`form-control `}
                      id="name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 form_details">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="minimum_investment">
                      Minimum Investment <span className="login-danger"></span>
                    </label>
                    <input
                      className={`form-control `}
                      id="minimum_investment"
                      name="minimum_investment"
                      type="text"
                      value={formData.minimum_investment}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="interest_rate">
                      Interest Rate <span className="login-danger"></span>
                    </label>
                    <input
                      className={`form-control `}
                      id="interest_rate"
                      name="interest_rate"
                      type="text"
                      value={formData.interest_rate}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="is_compound">
                      Compound <span className="login-danger"></span>
                    </label>
                    <input
                      className={`form-control `}
                      id="is_compound"
                      name="is_compound"
                      type="text"
                      value={formData.is_compound === 1 ? 'Applicable' : 'Not Applicable'}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="interest_rate">
                      Plan Duration <span className="login-danger"></span>
                    </label>
                    <select
                      className={`form-select ${errors.plan_duration ? "input-error" : ""
                        }`}
                      id="plan_duration"
                      name="plan_duration"
                      value={formData.plan_duration}
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      {data.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6 form_details mt-3">
                  <div className="form-group local-forms">
                    <label className="form-label" htmlFor="plan_duration_type">
                      Plan Duration Type <span className="login-danger"></span>
                    </label>
                    <select
                      className={`form-select`}
                      id="plan_duration_type"
                      name="plan_duration_type"
                      value={formData.plan_duration_type}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Type</option>
                      {datatype.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 form_details mt-3">
                  <div className="form-group local-forms">
                    <label>Description </label>
                    <textarea
                      className={`w-100 form-control `}
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      placeholder="Description"
                      rows="3"
                    />
                  </div>
                </div>
                <Col md={6}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="plan_image">
                      Plan Image
                    </label>
                    <input
                      className="form-control"
                      id="plan_image"
                      accept=".jpeg,.jpg,.png"
                      type="file"
                      onChange={handleImageChange}
                    />
                  </div>
                </Col>

                {fileimage ? (
                  <Col md={6}>
                    <div className="mb-3">
                      <div className="mt-3 image_box">
                        <img
                          src={fileimage}
                          alt="Selected Plan"
                          style={{ height: "150px", width: "150px" }}
                        />
                        <ImCross onClick={handleClearImageProfile} />
                      </div>
                    </div>
                  </Col>
                ) : baseurl ? (
                  <Col md={6}>
                    <div className="mb-3">
                      <div className="mt-3 image_box">
                        <img
                          className=""
                          src={`${baseurl}/${formData.plan_image}`}
                          alt="fileimage"
                          style={{ height: "150px", width: "150px" }}
                        />
                        {/* <ImCross onClick={handleClearImageProfile1} /> */}
                      </div>
                    </div>
                  </Col>

                ) : null}
                <Col md={12}>
                  <div className="mb-3 d-flex justify-content-end">
                    <button
                      onClick={handleSubmit}
                      // disabled={loading}
                      className="btn btn-sm btn-primary"
                      type="submit"
                    >
                      Update
                      {/* {loading && (
                      <Spinner
                        animation="border"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "10px",
                        }}
                      />
                    )} */}
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </form>

    </div>
  );
}
